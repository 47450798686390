import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "config";
import { defaultLabelColors } from "utils/constants";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <ButtonBase disableRipple disabled component={Link} to={config.defaultPath}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 992.9 513"
        // style="enable-background:new 0 0 992.9 513;"
        xmlSpace="preserve"
        style={{
          height: "100%",
          width: "100%",
          margin: "0px 20px",
          fill: defaultLabelColors.main_orange,
        }}
      >
        <g>
          <g id="Layer_1-2">
            <polygon
              style={{ fill: "#FA4514;" }}
              points="32.3,272.7 154.1,240.1 152.6,211.1 0,252 13.4,508.7 44.2,500.5 39,402.7 141.3,375.3 139.9,348.2 
			37.6,375.6"
            />
            <polygon
              style={{ fill: "#FA4514;" }}
              points="940.5,0 907,9 797.6,182.4 790.4,40.6 759.6,48.8 772.9,305.2 803.7,297 799.6,218.5 836.1,161.8 
			925.2,264.4 959,255.4 854.2,137.9"
            />
            <path
              style={{ fill: "#FA4514;" }}
              d="M697.1,65.6l10.6,199.6L556.4,102.9l-24,6.4c0,0,7.6,142.9,7.6,146.1c0.3,22.2-5.1,44-15.7,63.5
			c-3.8,7-8.2,13.6-13.2,19.7c-2.9,3.6-6,7-9.3,10.2c-20.9,20.9-48.4,33.9-77.8,36.8c-11.9,1.2-23.9,0.8-35.8-1.3
			c-9.4-1.6-18.6-4.3-27.4-8c-22.6-9.5-42.1-25.2-56.2-45.3l0.5-0.2c8.6-4.8,16.1-11.2,22.3-18.8c12.7-15.6,20.4-34.6,22.2-54.5
			c1-9.9,0.1-20-2.4-29.6c-2.2-8.1-5.6-15.9-10.2-22.9c-4.4-6.8-9.9-12.8-16.4-17.6c-6.4-4.6-13.6-7.9-21.3-9.7
			c-8.2-1.8-16.7-1.5-24.7,0.8L175.4,205l13.4,256.7l30.8-8.3l-4.9-92.2l59.5-16l1.7-0.8c18.4,28.2,45,49.9,76.3,62.2
			c9,3.5,18.3,6.3,27.7,8.1c15.6,3,31.5,3.7,47.3,2c8.6-0.9,17.1-2.5,25.5-4.7c23.1-6.2,44.6-17.6,62.7-33.3
			c7.3-6.2,14-13.1,20.1-20.5c1.5-1.9,3-3.8,4.5-5.8c1.7-2.3,3.3-4.5,4.8-6.9c17-25.5,26.3-55.4,26.7-86c0.1-6.4-5.2-98-5.2-98
			l149.2,158.8l25.7-6.9L727.9,57.3L697.1,65.6z M282,313.9l-19.5,5.2l-49.2,13.2l-5.5-106.7l66.7-17.9c4.4-1.2,9-1.2,13.4-0.1
			c4.4,1.2,8.5,3.3,12.1,6.1c3.9,3.1,7.3,6.9,9.9,11.1c3,4.6,5.2,9.6,6.6,14.9c2,7.5,2.6,15.4,1.8,23.2c-0.8,7.7-2.7,15.2-5.8,22.3
			c-2.9,6.8-7.1,12.9-12.4,18.2c-2.3,2.3-4.8,4.3-7.6,5.9C289.3,311.4,285.7,312.9,282,313.9"
            />
            <path
              style={{ fill: "#FA4514;" }}
              d="M382.6,363l8.6-31.8l40.5-156.8c13.5,33.9,53.3,120.1,64.4,153c3.3-3.3,6.4-6.7,9.3-10.2
			c5-6.2,9.4-12.8,13.2-19.7l-75.9-164.1l-26.4,7.1L355.2,355C364,358.7,373.2,361.4,382.6,363"
            />
          </g>
        </g>
      </svg>
    </ButtonBase>
  );
};

export default LogoSection;

export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",

  main_orange: "#ee6931",
  light_orange: "#f9c9b3",
  sand: "#f9d9b6",
  main_blue: "#3026f1",
  dark_blue: "#191847",
  orange_yellow: "#d3be00",
  green: "green",
  red: "#f10000",
};

export const dateRangeFilters = [
  { label: "All Time Records", value: "all_time" },
  { label: "This Year", value: "this_year" },
  { label: "This Quarter", value: "this_quarter" },
  { label: "This Month", value: "this_month" },
  { label: "This Week", value: "this_week" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Previous Week", value: "previous_week" },
  { label: "Previous Month", value: "previous_month" },
];

export const Size_Units = [
  {
    label: "m",
    value: 1,
  },
  {
    label: "cm",
    value: 2,
  },
  {
    label: "mm",
    value: 3,
  },
  {
    label: "in",
    value: 4,
  },
  {
    label: "ft",
    value: 5,
  },
];

export const Weight_Units = [
  { label: "kg", value: 1, unit: "kg" },
  { label: "metric ton", value: 2, unit: "mt" },
  { label: "ounce", value: 3, unit: "oz" },
  { label: "pound", value: 4, unit: "lb" },
  { label: "ton", value: 5, unit: "t" },
  { label: "g", value: 6, unit: "g" },
];

export const Volume = [
  { label: "l (litre)", value: 1 },
  { label: "gal (gallon)", value: 2 },
  { label: "bbl (barrel)", value: 3 },
];

export const Language = [
  { label: "English", value: "English" },
  { label: "French", value: "French" },
  { label: "Arabic", value: "Arabic" },
];

export const Date_Format = [
  { label: "D MMM YYYY", value: "D MMM YYYY" },
  { label: "DD-MMM-YYYY", value: "DD-MMM-YYYY" },
  { label: "DD-MMM-YY", value: "DD-MMM-YY" },
  { label: "DD.MMM.YY", value: "DD.MMM.YY" },
  { label: "DD-MM-YYYY", value: "DD-MMM-YY" },
  { label: "YYYY-MMM-DD", value: "YYYY-MMM-DD" },
];

export const Time_Format = [
  {
    label: "h:mm am/pm",
    value: "h:mm a",
  },
  {
    label: "hh:mm 24-hours",
    value: "HH:mm",
  },
  {
    label: "hh:mm am/pm",
    value: "hh:mm a",
  },
  {
    label: "hh:mm:ss 24-hours",   
    value: "HH:mm:ss",
  },
  {
    label: "hh:mm:ss am/pm",
    value: "hh:mm:ss a",
  },
];

export const Distance_Unit = [
  { label: "km", value: "KM" },
  { label: "mile", value: "Mile" },
];

export const label_to_value = {
 
} as any;

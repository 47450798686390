import MainLayout from "layout/MainLayout";
import {
  Dashboard,
  MyPayments,
  MyProfile,
  NotFound,
  OrderDetails,
  Orders,
  ReturnOrders,
  Settings,
  SupportChat,
} from "./PageAsync";
import PropTypes from "prop-types";
// ==============================|| MAIN ROUTING ||============================== //

// export default MainRoutes;

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard classes={classes} />,
      },
      {
        path: "/dashboard",
        element: <Dashboard classes={classes} />,
      },
      {
        path: "/orders",
        element: <Orders classes={classes} />,
      },
      {
        path: "/order-details/:id",
        element: <OrderDetails classes={classes} />,
      },
      {
        path: "/returns",
        element: <ReturnOrders classes={classes} />,
      },
      {
        path: "/payments",
        element: <MyPayments classes={classes} />,
      },
      {
        path: "/settings",
        element: <Settings classes={classes} />,
      },
      {
        path: "/profile",
        element: <MyProfile classes={classes} />,
      },
      {
        path: "/support-chat",
        element: <SupportChat classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};

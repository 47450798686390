import { createSlice } from "@reduxjs/toolkit";

export interface BreadcrumbState {
  title: string;
  sub_title: string;
}

const initialState: BreadcrumbState = {
  title: "Frank",
  sub_title: "",
};

export const BreadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setTitle: (state: BreadcrumbState, action: any) => {
      state.title = action.payload;
    },
    setSubTitle: (state: BreadcrumbState, action: any) => {
      state.sub_title = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTitle,setSubTitle } = BreadcrumbSlice.actions;

export default BreadcrumbSlice.reducer;

import Toast from "ui-components/Toast";
import { defaultLabelColors } from "./constants";
import moment from "moment";

export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const numberFormatWithoutCurrency = (value: number) =>
  Intl.NumberFormat("en-US").format(value);

const numToWordObj = {
  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten",
  11: "Eleven",
  12: "Twelve",
  13: "Thirteen",
  14: "Fourteen",
  15: "Fifteen",
  16: "Sixteen",
  17: "Seventeen",
  18: "Eighteen",
  19: "Nineteen",
  20: "Twenty",
  30: "Thirty",
  40: "Forty",
  50: "Fifty",
  60: "Sixty",
  70: "Seventy",
  80: "Eighty",
  90: "Ninety",
} as any;

const placement = {
  100: " Hundred",
  1000: " Thousand",
  1000000: " Million",
  1000000000000: " Trillion",
};

export const convertNumToWord = (num: any): any => {
  const limiter = (val: any) => (num < val) as any;
  const limiterIndex = Object.keys(placement).findIndex(limiter) as any;
  const limiterKey = Object.keys(placement)[limiterIndex] as any;
  const limiterVal = Object.values(placement)[limiterIndex - 1] as any;
  const limiterMod = Object.keys(placement)[limiterIndex - 1] as any;

  if (numToWordObj[num]) {
    return numToWordObj[num];
  }

  if (num < 100) {
    const whole = Math.floor(num / 10) * 10;
    const part = num % 10;
    return numToWordObj[whole] + " " + numToWordObj[part];
  }

  if (num < limiterKey) {
    const whole = Math.floor(num / limiterMod);
    const part = num % limiterMod;
    if (part === 0) {
      return convertNumToWord(whole) + limiterVal;
    }
    return (
      convertNumToWord(whole) + limiterVal + " and " + convertNumToWord(part)
    );
  }
};

export const getStatusLabel = (status: string): string => {
  switch (status) {
    case "waiting":
      return "Waiting";

    case "pending":
      return "Booked";

    case "accepted":
      return "Accepted";

    case "booked":
      return "Booked";

    case "picked":
      return "Picked";

    case "delivered":
      return "Delivered";

    case "cancelled_transporter":
      return "Cancelled by Transpoter";
    case "cancelled_back_office_admin":
      return "Cancelled by BO-Admin";
    case "cancelled_store_admin":
      return "Cancelled by Store";
    case "cancelled_customer":
      return "Cancelled by Customer";

    default:
      return status;
  }
};

export const getStatusLabelColor = (status: string): string => {
  switch (status) {
    case "waiting":
      return defaultLabelColors.main_blue;

    case "pending":
      return "";

    case "accepted":
      return defaultLabelColors.green;

    case "picked":
      return defaultLabelColors.green;

    case "delivered":
      return defaultLabelColors.green;

    case "cancelled_transporter":
      return defaultLabelColors.red;
    case "cancelled_back_office_admin":
      return defaultLabelColors.red;
    case "cancelled_store_admin":
      return defaultLabelColors.red;
    case "cancelled_customer":
      return defaultLabelColors.red;
    default:
      return "";
  }
};

export const convertMilisecondsToDuration = (milliseconds: number): string => {
  try {
    let sec_num = milliseconds / 1000; //parseInt( / 1000);
    let secsUsed = 0;
    let years = Math.floor(sec_num / 31536000);
    if (years > 0) {
      secsUsed += years * 31536000;
    }
    let months = Math.floor((sec_num - secsUsed) / 2628288);
    if (months > 0) {
      secsUsed += months * 2628288;
    }
    let weeks = Math.floor((sec_num - secsUsed) / 604800);
    if (weeks > 0) {
      secsUsed += weeks * 604800;
    }
    let days = Math.floor((sec_num - secsUsed) / 86400);
    if (days > 0) {
      secsUsed += days * 86400;
    }
    let hours = Math.floor((sec_num - secsUsed) / 3600);
    if (hours > 0) {
      secsUsed += hours * 3600;
    }
    let minutes = Math.floor((sec_num - secsUsed) / 60);
    if (minutes > 0) {
      secsUsed += minutes * 60;
    }
    let seconds = sec_num - secsUsed;
    if (years > 1) {
      return "More than 1 year";
    } else if (years > 0) {
      return (
        years + " Years " + months + " Months "
        // weeks +
        // " Weeks " +
        // days +
        // " Days " +
        // hours +
        // " Hours " +
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (months > 0) {
      return (
        months + " Months " + weeks + " Weeks "
        //  +
        // days +
        // " Days " +
        // hours +
        // " Hours " +
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (weeks > 0) {
      return (
        weeks +
        ` ${weeks > 1 ? "Weeks" : "Week "}` +
        days +
        ` ${days > 1 ? "Days" : "Day"}` //+
        // hours +
        // " Hours " +
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (days > 0) {
      return (
        days + " Days " + hours + " Hours " //+
        // minutes +
        // " Minutes " +
        // seconds +
        // " Seconds"
      );
    } else if (hours > 0) {
      return hours + " Hours " + minutes + " Minutes "; // + seconds + " Seconds";
    } else if (minutes > 0) {
      return minutes + " Minutes " + seconds + " Seconds";
    } else if (seconds > 0) {
      return seconds + " Seconds";
    } else if (seconds === 0) {
      return "Less than a minute";
    } else {
      return (
        days +
        " Days " +
        hours +
        " Hours " +
        minutes +
        " Minutes " +
        seconds +
        " Seconds"
      );
    }
  } catch (error) {
    return "N/A";
  }
};

export const getGender = (gender: string): string => {
  switch (gender) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default:
      return "Male";
  }
};

export const getImageNameFromURL = (urlInput: string): string => {
  const imageFullnameResult = urlInput?.match(/.+(\/|%2F)(.+)/);

  if (!imageFullnameResult) return "";

  const imageFullname = imageFullnameResult[2];

  const imageNameResult = imageFullname?.match(
    /.+(jpg|png|svg|jpeg|webp|bmp|gif)/
  );

  if (!imageNameResult) return imageFullname;

  const imageName = imageNameResult[0];

  if (!imageName) return "";

  return imageName;
};

export const copyToClipboard = async (
  section_name: any,
  data: any,
  handleShowSnack: any,
  has_label_feilds?: any
) => {
  try {
    if (!navigator.clipboard) {
      handleShowSnack(
        "Your browser dose not support Copy to Clipboard API",
        "error"
      );
      return;
    }
    let copied_data = `**${section_name}**\n`;
    if (has_label_feilds) {
      document
        .querySelectorAll(`#${data}`)[0]
        ?.childNodes?.forEach((item: any, index: any) => {
          if (item?.children[0]?.className?.includes("_seperator")) return;
          let code = "",
            mobile = "",
            unit_ = "",
            value_2 = "",
            value_3 = "";

          if (!item.children?.[1]?.innerText) {
            code =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 2
              ]?.innerText;

            mobile =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 1
              ]?.children?.[1]?.innerText;
          }
          copied_data += `${item.children?.[0]?.innerText || ""}: ${
            `${
              item.children?.[1]?.innerText
                ? item.children?.[1]?.innerText + " "
                : ""
            }${value_2}${value_3}${unit_ || ""}` ||
            `${code || ""} ${mobile || ""}` ||
            ""
          }\n`;
        });
    } else {
      document
        .querySelectorAll(`#${data}`)[0]
        ?.childNodes?.forEach((item: any, index: any) => {
          if (item?.children[0]?.className?.includes("_seperator")) return;
          let code = "",
            mobile = "",
            unit_ = "",
            value_2 = "",
            value_3 = "";

          if (!item.children?.[0]?.[0]?.value) {
            code =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 2
              ]?.innerText;

            mobile =
              item?.children?.[0]?.lastElementChild?.children?.[
                item?.children?.[0]?.lastElementChild?.children?.length - 1
              ]?.children?.[0]?.[0]?.value;
          }
          copied_data += `${item.children?.[0]?.firstChild?.innerText || ""}: ${
            `${
              item.children?.[0]?.[0]?.value
                ? item.children?.[0]?.[0]?.value + " "
                : ""
            }${value_2}${value_3}${unit_ || ""}` ||
            `${code || ""} ${mobile || ""}` ||
            ""
          }\n`;
        });
    }
    await navigator.clipboard.writeText(copied_data);
    Toast.showSuccess(`${section_name ?? ""} Copied to clipboard`);
  } catch (error) {
    console.log(error);
  }
};

export const getDateString = (startDate: any, endDate: any) => {
  const duration = moment.duration(endDate.diff(startDate));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let result = "";

  if (years) {
    result += `${years} year${years > 1 ? "s" : ""} `;
  }
  if (months) {
    result += `${months} month${months > 1 ? "s" : ""} `;
  }
  if (days) {
    result += `${days} day${days > 1 ? "s" : ""} `;
  }
  if (hours) {
    result += `${hours} hour${hours > 1 ? "s" : ""} `;
  }
  if (minutes) {
    result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
  }
  if (seconds) {
    result += `${seconds} second${seconds > 1 ? "s" : ""} `;
  }

  return result.trim();
};

export const business_hours = [
  {
    day_of_week: "Monday",
    opening_time: "09:00",
    closing_time: "18:00",
    is_closed: false,
  },
  {
    day_of_week: "Tuesday",
    opening_time: "09:00",
    closing_time: "18:00",
    is_closed: false,
  },
  {
    day_of_week: "Wednesday",
    opening_time: "09:00",
    closing_time: "18:00",
    is_closed: false,
  },
  {
    day_of_week: "Thursday",
    opening_time: "09:00",
    closing_time: "18:00",
    is_closed: false,
  },
  {
    day_of_week: "Friday",
    opening_time: "09:00",
    closing_time: "18:00",
    is_closed: false,
  },
  {
    day_of_week: "Saturday",
    opening_time: "09:00",
    closing_time: "18:00",
    is_closed: false,
  },
  {
    day_of_week: "Sunday",
    opening_time: "00:00",
    closing_time: "00:00",
    is_closed: true,
  },
];
export const darkModeWithBlue = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#121212", // Dark background color
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue text color
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#121212", // Dark text stroke color
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#64B5F6", // Blue land parcel text color
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue POI color
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue POI text color
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue park color
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue park text color
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#263238", // Dark road color
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue road text color
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#263238", // Dark blue highway color
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: defaultLabelColors.color_h, // Blue highway text color
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue local road text color
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue transit line color
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#0D47A1", // Dark blue transit station color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#003a7d", // Dark blue water color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#BBDEFB", // Blue water text color
      },
    ],
  },
];

export const darkModeMapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121", // Dark background color
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575", // Gray text color
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121", // Dark text stroke color
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575", // Gray administrative area color
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575", // Gray land parcel text color
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#424242", // Dark landscape color
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575", // Gray POI color
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#424242", // Dark park color
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#616161", // Dark road color
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575", // Gray arterial road text color
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#616161", // Dark highway color
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575", // Gray highway text color
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575", // Gray local road text color
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#424242", // Dark transit color
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575", // Gray transit station color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#212121", // Dark water color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575", // Gray water text color
      },
    ],
  },
];

/*
  Created By Muhammad Hassaan
  Date 07 April 2023
*/
import { Grid } from "@mui/material";
import useStyles from "routes/style-jss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import React from "react";
import { Circle } from "@mui/icons-material";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

const Breadcrumbs = () => {
  const classes = useStyles() as any;
  let BreadcrumbTitle = useSelector((state: RootState) => state.breadcrumb);
  const [time, setTime] = React.useState(new Date());
let getSettingsData = getDataFromLocalStorage("settings")


  React.useEffect(() => {
    let timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.breadcrumb_container}>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          md={7}
          item
          className={classNames(classes.align_center, classes.breadcrumb_head)}
        >
          {BreadcrumbTitle?.title || ""}
          {BreadcrumbTitle?.sub_title && (
            <Circle style={{ margin: "0px 15px", fontSize: "1.2rem" }} />
          )}
          {BreadcrumbTitle?.sub_title || ""}
        </Grid>
        <Grid
          xs={12}
          md={5}
          item
          className={classNames(classes.align_end, classes.direction_column)}
        >
          <div className={classNames(classes.breadcrumb_time)}>
            {moment(time).format(getSettingsData?.time_format ?? "hh:mm A")}
          </div>
          <div className={classNames(classes.breadcrumb_description)}>
            {moment().format("dddd[,] DD MMMM YYYY")}
          </div>
          {/* <div className={classNames(classes.breadcrumb_desctiption)}>Asia/Karachi</div> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Breadcrumbs;

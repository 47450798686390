// import dashboard from "./dashboard";
// import pages from "./pages";
// import utilities from "./utilities";
// import other from "./other";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "orders",
      title: "Orders",
      type: "group",
      children: [
        {
          id: "orders",
          title: "All Orders",
          type: "item",
          url: "/orders",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "profile",
      title: "My Profile",
      type: "group",
      children: [
        {
          id: "profile",
          title: "My Profile",
          type: "item",
          url: "/profile",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "payments",
      title: "Payments",
      type: "group",
      children: [
        {
          id: "payments",
          title: "Payments",
          type: "item",
          url: "/payments",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
          disable:true,
          disabled:true,
        },
      ],
    },
    {
      id: "_chat",
      title: "Chat",
      type: "group",
      children: [
        {
          id: "support-chat",
          title: "Support Chat",
          type: "item",
          url: "/support-chat",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "settings",
      title: "Settings",
      type: "group",
      children: [
        {
          id: "settings",
          title: "Settings",
          type: "item",
          url: "/settings",
          //   icon: icons.IconDashboard,
          breadcrumbs: true,
        },
      ],
    },
  ],
};

export default menuItems;

import { get, post } from "./HttpProvider";

const getFromApi = (url: any, permission_name: any) =>
  get(url, permission_name, null);

const postFromApi = (url: any, data: any, permission_name: any) =>
  post(url, data, permission_name, null);

export const apiServices = {
  getFromApi,
  postFromApi,
};
export default apiServices;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import config from "config";
import { all_orders } from "dummy-data";
import apiServices from "services/RequestHandler";
import { countries } from "utils/countries";
import { cities } from "utils/cities";

export interface ParentState {
  countries: any;
  cities: any;
  panel: {
    profileInfoAcc: boolean;
    warehouseInfo: boolean;
    
  };
}

interface APIParams {
  end_point: string;
}

const initialState: ParentState = {
  countries: countries,
  cities: cities,
  panel: {
    profileInfoAcc: true,
    warehouseInfo: true,
   },
};

export const parentSlicer = createSlice({
  name: "parent",
  initialState,
  reducers: {
    clearCountries: (state: ParentState, action: any) => {
      state.countries = [];
    },
    clearCities: (state: ParentState, action: any) => {
      state.cities = [];
    },
    handleToggleAllPanels: (state: ParentState, action: any) => {
      state.panel = {
        profileInfoAcc: action.payload,
        warehouseInfo:action.payload
      };
    },
    handleTogglePanel: (state: any, action: any) => {
      state.panel[action.payload.name] = action.payload.value;
    },
  },
 
});

export const {
  clearCountries,
  clearCities,
  handleToggleAllPanels,
  handleTogglePanel,
} = parentSlicer.actions;

export default parentSlicer.reducer;

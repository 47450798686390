import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import {
  getStatusLabel,
  getStatusLabelColor,
  numberFormatWithoutCurrency,
} from "utils/helper";

let userSettings = getDataFromLocalStorage("settings");
export interface OrderState {
  all_orders: any;
  all_orders_data: any;
  selected_order: object;
  activity: boolean;
  paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

interface APIParams {
  end_point: string;
}

const initialState: OrderState = {
  all_orders: [],
  all_orders_data: [],
  selected_order: {},
  activity: true,
};

export const orderRequests = createAsyncThunk(
  "spaces/all-orders-by-space",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const returnOrderRequests = createAsyncThunk(
  "stores/order-return",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      let returnOrderData = response?.result?.result;
      return returnOrderData;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setSelectedOrder: (state: OrderState, action: any) => {
      state.selected_order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderRequests.pending, (state, { payload }) => {
        state.activity = false;
      })

      .addCase(orderRequests.fulfilled, (state, { payload }) => {
        try {
          let orders = [] as any;
          const data = payload?.data;
          for (let index = 0; index < data?.length; index++) {
            const item = data?.[index];
            orders.push([
              item?.id || "- - -",
              item?.customer_name ?? "- - -",

              moment(item?.expected_pickup_date).format(
                userSettings?.date_format ?? "DD-MMM-YYYY"
              ) || "- - -",
              moment(item?.expected_delivery_date).format(
                userSettings?.date_format ?? "DD-MMM-YYYY"
              ) || "- - -",

              `$ ${
                numberFormatWithoutCurrency(
                  Number(item?.shipping_charges ?? 0)
                ) ?? "- - -"
              }`,
              {
                name: getStatusLabel(item?.order_status) || "- - -",
                is_bool: true,
                color: getStatusLabelColor(item?.order_status),
                style: { color: getStatusLabelColor(item?.order_status) },
              },
            ]);
          }
          state.all_orders_data = payload?.data;
          state.all_orders = orders;
          state.paginate_data = {
            current_count: payload?.current_count,
            current_page: payload?.current_page,
            previous_count: payload?.previous_count,
            total_pages: payload?.total_pages,
            total_count: payload?.total_count,
          };

          // }
          // state.all_orders_data = payload?.result?.result;
          // state.all_orders = orders;
          // // resultCount
          // state.paginate_data = {
          //   current_count: payload?.current_count,
          //   // current_page: payload?.current_page,
          //   previous_count: payload?.previous_count,
          //   total_pages: payload?.total_pages ?? 1,
          //   total_count: payload?.total_count,
          // };

          state.activity = false;
        } catch (error) {
          state.activity = false;
          console.log(error);
        }
      })

      .addCase(returnOrderRequests.fulfilled, (state, { payload }) => {
        try {
        } catch (error) {
          state.activity = false;
          console.log(error);
        }
      });
  },
});

export const { setSelectedOrder } = orderSlice.actions;

export default orderSlice.reducer;

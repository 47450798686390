import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Login = Loadable(lazy(() => import("views/Auth/Login")));

export const Signup = Loadable(lazy(() => import("views/Auth/Signup")));

export const Dashboard = Loadable(lazy(() => import("views/Dashboard")));

export const NotFound = Loadable(lazy(() => import("views/NotFound")));

export const Orders = Loadable(lazy(() => import("views/Orders/index")));

export const OrderDetails = Loadable(lazy(() => import("views/Orders/OrderDetails")));

export const ReturnOrders = Loadable(lazy(() => import("views/ReturnOrders")));

export const Settings = Loadable(lazy(() => import("views/Settings")));

export const MyProfile = Loadable(lazy(() => import("views/MyProfile/index")));

export const MyPayments = Loadable(lazy(() => import("views/Payments/index")));

export const SupportChat = Loadable(lazy(() => import("views/Chats/index")));






import { configureStore } from "@reduxjs/toolkit";
import BreadcrumbSlice from "./breadcrumbReducer";
import drawerReducer from "./drawerReducer";
import orderReducer from "./orderReducer";
import parentReducers from "./parentReducers";
import paymentReducers from "./paymentReducers";

// ==============================|| REDUX - MAIN STORE ||============================== //

export const store = configureStore({
  reducer: {
    orders:orderReducer,
    drawer: drawerReducer,
    breadcrumb: BreadcrumbSlice,
    parent: parentReducers,
    payment:paymentReducers
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { getDataFromLocalStorage } from "utils/LocalStore.util";

// routes
// import MainRoutes from "./MainAppRoutes";

export function AppRoute() {
  const classes = useStyles() as any;

  let pvt_routes = MainAppRoutes({ classes });

  let auth_element = AuthenticationRoutes({ classes });

  let user = getDataFromLocalStorage("user");

  // eslint-disable-next-line
  if (!user) return useRoutes([auth_element]);
  // eslint-disable-next-line
  return useRoutes([pvt_routes]);
}
export default AppRoute;
